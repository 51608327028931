const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://uongbi2-api.cgis.asia/",
    domainUserSide: "http://localhost:3000",
    domainAdminSide: "http://localhost:3000",
    domainName: "",
    doMainNameGeoServer: "https://geoserver.phutho.gov.vn/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geoserver.phutho.gov.vn/geoserver/uongbi_demo/wms",
    datastore: 'phutho',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
  },
  production: {
    api: "https://uongbi2-api.cgis.asia/",
    domainUserSide: "http://uongbi2.cgis.asia",
    domainAdminSide: "https://admin.uongbi2.cgis.asia",
    domainName: "uongbi2.cgis.asia",
    doMainNameGeoServer: "https://geoserver.phutho.gov.vn/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geoserver.phutho.gov.vn/geoserver/uongbi_demo/wms",
    datastore: 'phutho',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
  },
};

module.exports = apiEnvironment[env];

// https://uongbi.cgis.asia/
// uongbi.cgis@gmail.com
// Uongbi123456!

// uongbi2.cgis.asia
// uongbi2-api.cgis.asia
// admin.uongbi2.cgis.asia

// https://uongbi2-api.cgis.asia/swagger/v1/index.html
// "email": "uongbi2.cgis@gmail.com",
// "password": "Uongbi123456!",